import * as React from 'react'
import clsx from 'clsx'
import Offcanvas from 'react-bootstrap/Offcanvas'
import classes from './styles.module.scss'

interface HUIDrawerProps {
  title?: string | JSX.Element
  children: JSX.Element | JSX.Element[]
  hideHeader?: boolean
  showHeaderTitle?: boolean
  drawerFooter?: JSX.Element
  isOpen: boolean
  onClose: () => void
  isForm?: boolean
  handleFormSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void
  bodyContainerStyle?: Record<string, string> | {}
  overrideStyles?: Record<string, string> | {}
  overrideHeaderStyles?: Record<string, string>
  overrideOffcanvasHeaderStyles?: Record<string, string>
  showDefaultFooter?: boolean
  onCancel?: () => void
  showFooter?: boolean
  onOk?: () => void
  okText?: string
  cancelText?: string
  submitBtnDisabled?: boolean
  ref: any
  position?: any
  showCloseBtn?: boolean
  headerButton?: JSX.Element
  footerButton?: JSX.Element
  footerStyle?: Record<string, string> | {}
  scroll?: boolean
  backdrop?: boolean
  transparentBackdrop?: boolean
}

const HUIDrawer = React.forwardRef<HTMLDivElement, HUIDrawerProps>(
  (
    {
      title,
      children,
      hideHeader,
      showHeaderTitle = true,
      drawerFooter,
      isOpen,
      onClose,
      isForm,
      handleFormSubmit,
      overrideStyles,
      onCancel,
      onOk,
      showDefaultFooter = false,
      okText = 'Add',
      cancelText = 'Cancel',
      bodyContainerStyle,
      submitBtnDisabled,
      position = 'end',
      showFooter = true,
      showCloseBtn = true,
      overrideHeaderStyles = {},
      overrideOffcanvasHeaderStyles = {},
      headerButton,
      footerButton,
      footerStyle = {},
      scroll,
      backdrop = true,
      transparentBackdrop = false,
    },
    ref,
  ) => {
    return (
      <Offcanvas
        show={isOpen}
        onHide={onClose}
        placement={position}
        style={{zIndex: 1040, ...overrideStyles}}
        scroll={scroll}
        backdrop={backdrop}
        backdropClassName={clsx({[classes.hideBackdrop]: transparentBackdrop})}
      >
        {!hideHeader && (
          <Offcanvas.Header closeButton={showCloseBtn} style={overrideOffcanvasHeaderStyles}>
            {typeof title === 'object' ? (
              title
            ) : (
              <Offcanvas.Title className="me-2">
                {showHeaderTitle && (
                  <DrawerHeader title={title} overrideHeaderStyles={overrideHeaderStyles} />
                )}
              </Offcanvas.Title>
            )}
            {headerButton}
          </Offcanvas.Header>
        )}
        <Offcanvas.Body
          style={{margin: hideHeader ? '-30px 0 0 0' : '0 0 90px 0', ...bodyContainerStyle}}
          ref={ref}
          className="pt-0"
        >
          {isForm ? (
            <form onSubmit={handleFormSubmit}>
              <div className="card-body h-100 py-0 px-0">{children}</div>
              {showFooter && (
                <div className={clsx('card-footer', classes.footer)}>
                  {showDefaultFooter ? (
                    <DrawerFooter
                      onCancel={onCancel || onClose}
                      onOk={onOk}
                      okText={okText}
                      cancelText={cancelText}
                      submitBtnDisabled={submitBtnDisabled}
                      footerButton={footerButton}
                    />
                  ) : (
                    drawerFooter
                  )}
                </div>
              )}
            </form>
          ) : (
            <>
              <div className="card-body h-100 py-0 px-0">{children}</div>
              {showFooter && (
                <div className={clsx('card-footer', classes.footer)} style={footerStyle}>
                  {showDefaultFooter ? (
                    <DrawerFooter
                      onCancel={onCancel || onClose}
                      onOk={onOk}
                      okText={okText}
                      cancelText={cancelText}
                      submitBtnDisabled={submitBtnDisabled}
                      footerButton={footerButton}
                    />
                  ) : (
                    drawerFooter
                  )}
                </div>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    )
  },
)

function DrawerHeader({title, overrideHeaderStyles}) {
  return (
    <div className="card-header">
      <div className="card-title">
        {Object.keys(overrideHeaderStyles).length ? (
          <span
            // className="fs-4 fw-bolder me-1 mb-2 lh-1"
            className={classes.offCanvasTitle}
            style={{...overrideHeaderStyles}}
          >
            {title}
          </span>
        ) : (
          <span
            // className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
            className={classes.offCanvasTitle}
            style={{...overrideHeaderStyles}}
          >
            {title}
          </span>
        )}
      </div>
    </div>
  )
}

interface DrawerFooterProps {
  okText?: string
  onOk?: () => void
  cancelText?: string
  onCancel: () => void
  submitBtnDisabled?: boolean
  footerButton?: JSX.Element
}

function DrawerFooter({
  okText,
  onOk,
  cancelText,
  onCancel,
  submitBtnDisabled,
  footerButton,
}: DrawerFooterProps) {
  const handleClose = () => onCancel && onCancel()

  return (
    <div className={classes.drawerFooter}>
      <div className="d-flex align-items-center">
        <button className={classes.cancelBtn} type="button" onClick={handleClose}>
          {cancelText}
        </button>
        <button
          disabled={submitBtnDisabled}
          type="submit"
          className={classes.submitBtn}
          onClick={() => onOk && onOk()}
        >
          {okText}
        </button>
      </div>
      {footerButton}
    </div>
  )
}

export default HUIDrawer
