import {UploadFileType} from 'hybrid-ui/components/hui-upload-v2/HUIUpload'
import {ICONS} from 'app/utils/constants/icon'
import {SOFTWARE_ACTION, SOFTWARE_TYPE, SoftwareConfigResponse} from 'types/software'

export type LicenseUserUpdatePayloadDetail = {
  id: string
  billingFrom: string
  type: string
}

export const getDocsUploadPayload = (documents: UploadFileType[]) => {
  const data = documents.map(document => {
    return {
      file_name: document.fileName,
      doc_link: document.url,
    }
  })
  return {documents: data}
}

export const getActionMeta = action => {
  switch (action) {
    case SOFTWARE_ACTION.ADDED:
      return {icon: ICONS.plusCircle}
    case SOFTWARE_ACTION.UPDATED:
      return {icon: ICONS.textboxEdit}
    case SOFTWARE_ACTION.ASSIGNED:
      return {icon: ICONS.userPlus}
    case SOFTWARE_ACTION.REMOVED:
      return {icon: ICONS.userMinus}
    case SOFTWARE_ACTION.DELETED:
      return {icon: ICONS.minusCircle}
    case SOFTWARE_ACTION.ARCHIVED:
      return {icon: ICONS.archive}
    default:
      return {icon: ICONS.sidebar.zenIT.software}
  }
}

export const softwareTypeMap = {
  [SOFTWARE_TYPE.INTEGRATED]: 'Integrated',
  [SOFTWARE_TYPE.MANUAL]: 'Manual',
  [SOFTWARE_TYPE.HYBRID]: 'Hybrid',
}

export const isIntegratedSoftware = (type?: SOFTWARE_TYPE): boolean => {
  return type === SOFTWARE_TYPE.INTEGRATED
}
export const isManualSoftware = (type?: SOFTWARE_TYPE): boolean => {
  return type === SOFTWARE_TYPE.MANUAL
}
export const isProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
) => {
  return type === SOFTWARE_TYPE.INTEGRATED && config?.provision === false
}
export const isDeProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
) => {
  return type === SOFTWARE_TYPE.INTEGRATED && config?.deprovision === false
}
export const isManualOrDeprovisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
): boolean => {
  return (
    type === SOFTWARE_TYPE.MANUAL ||
    (type === SOFTWARE_TYPE.INTEGRATED && config?.deprovision === false)
  )
}
export const isManualOrProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
): boolean => {
  return (
    type === SOFTWARE_TYPE.MANUAL ||
    (type === SOFTWARE_TYPE.INTEGRATED && config?.provision === false)
  )
}
