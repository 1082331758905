import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {format} from 'date-fns'
import {useMutate} from 'app/utils/hooks/useMutate'
import {SOFTWARE_TRACKING_TOASTS} from 'app/utils/constants/toast'
import {
  type SoftwareQueries,
  GoogleGroupAllUsersQueries,
  GoogleGroupsQueries,
  GoogleGroupUsersQueries,
  SoftwareActivityQueries,
  SoftwareDetailUsersListQueries,
  SoftwareProvisionQueries,
} from 'app/store/software'
import {getActionMeta} from 'app/modules/software/utils/helper'
import {
  SoftwareLicenseService,
  SoftwareService,
  SoftwareUserService,
  SoftwareUtilService,
} from './software.service'
import {softwareKeys} from '../query-key-factory'
import {SoftwareProvDeprovRequest} from 'types/software'

const svc = new SoftwareService()
const licenseSvc = new SoftwareLicenseService()
const userSvc = new SoftwareUserService()
const utilSvc = new SoftwareUtilService()

export const useGetSoftwares = (query: SoftwareQueries) => {
  const res = useQuery({
    queryKey: softwareKeys.list(query),
    queryFn: () => svc.getSoftwares(query),
  })

  return {
    softwares: res.data?.data ?? [],
    metaData: res.data?.meta_data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useDeleteSoftware = () => {
  return useMutate(
    svc.deleteSoftware,
    softwareKeys.list(),
    SOFTWARE_TRACKING_TOASTS.deleteSoftwareTracking,
  )
}

export const useGetUserSoftware = (userId, queries) => {
  const res = useQuery({
    queryKey: [`user-software-${userId}`, queries],
    queryFn: () => svc.getUserSoftware(userId, queries),
  })

  return {
    userSoftware: res.data?.data || [],
    isPending: res.isPending,
    isError: res.isError,
    metaData: res?.data?.meta_data,
  }
}

export const useGetSoftwareDetails = (softwareId?: string) => {
  const res = useQuery({
    queryKey: softwareKeys.detail(softwareId || ''),
    queryFn: () => svc.getSoftwareDetails(softwareId),
    enabled: softwareId ? true : false,
  })
  return {software: res.data?.data, isPending: res.isPending, isError: res.isError}
}

export const useAddSoftwareDocs = (softwareId: string) => {
  return useMutate(
    svc.addSoftwareDocs,
    softwareKeys.documents(softwareId),
    SOFTWARE_TRACKING_TOASTS.docsAddedSuccessfully,
  )
}

export const useDeleteSoftwareDocs = (softwareId: string) => {
  return useMutate(
    svc.removeSoftwareDocs,
    softwareKeys.documents(softwareId),
    SOFTWARE_TRACKING_TOASTS.docsRemovedSuccessfully,
  )
}

export const useGetSoftwareDocs = (softwareId?: string) => {
  const res = useQuery({
    queryKey: softwareKeys.documents(softwareId || ''),
    queryFn: () => svc.getSoftwareDocs(softwareId || ''),
  })
  return {
    docs: res.data || [],
    fetchingDocs: res.isPending,
    isErrorDocs: res.isError,
  }
}

export const useGetSoftwareListFilters = () => {
  const res = useQuery({
    queryKey: softwareKeys.listFilters(),
    queryFn: svc.getSoftwareListFilters,
  })
  return {filters: res.data, isPending: res.isPending, isError: res.isError}
}

export const useGetSoftwaresUserFilters = () => {
  const res = useQuery({
    queryKey: softwareKeys.usersFilters(),
    queryFn: svc.getSoftwareUserFilters,
  })
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetSoftwareActivity = (query: SoftwareActivityQueries, softwareId: string) => {
  const response = useInfiniteQuery({
    queryKey: softwareKeys.activity(softwareId, query),
    queryFn: ({pageParam}) => svc.getSoftwareActivity(query, pageParam, softwareId),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    enabled: softwareId ? true : false,
    initialPageParam: 0,
  })

  const totalActivity = response?.data?.pages?.map(page => page.data).flat()
  const activities = totalActivity?.map(activity => ({
    date: format(new Date(activity.created_at), "do MMM yyyy ' at ' h:mm a"),
    content: activity.text,
    ...getActionMeta(activity.action),
  }))
  const metaData = response?.data?.pages[0]?.meta_data

  return {
    activities: activities ?? [],
    fetchingActivity: response.isPending,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    metaData,
    ...response,
  }
}

export const useGetSoftwareUsers = query => {
  const res = useQuery({
    queryKey: softwareKeys.users(query),
    queryFn: () => svc.getSoftwareUsers(query),
  })

  return {
    users: res.data?.data || [],
    fetchingUsers: res.isPending,
    errorFetchingUsers: res.isError,
    metaData: res?.data?.meta_data,
  }
}

// * software summary

export const useGetSoftwareInsights = ({from, to}: {from?: string; to?: string}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryInsights(),
    queryFn: () => svc.getSoftwareInsights({from, to}),
  })
  return {
    insights: resp.data,
    isLoading: resp.isPending,
  }
}

export const useGetSoftwareActiveUsers = ({from, to}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryActiveUsers(),
    queryFn: () => svc.getSoftwareActiveUsers({from, to}),
  })
  return {
    users: resp.data || [],
    ...resp,
  }
}

export const useGetSoftwareUsersDept = ({from, to}: {from?: string; to?: string}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryUsersDept(),
    queryFn: () => svc.getSoftwareUsersDept({from, to}),
  })
  return {
    data: resp.data || [],
    isLoading: resp.isPending,
    isError: resp.isError,
  }
}

export const useGetSoftwareAppsUsage = ({from, to}: {from?: string; to?: string}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryAppsUsage(),
    queryFn: () => svc.getSoftwareAppsUsage({from, to}),
  })
  return {
    apps: resp.data || [],
    ...resp,
  }
}

export const useFetSoftwareDetailInsights = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.summaryInsights(softwareId),
    queryFn: () => svc.getSoftwareDetailInsights({softwareId}),
    enabled: !!softwareId,
  })
  return {
    insights: res.data?.data || [],
    ...res,
  }
}

export const useGetSoftwareDetailActiveUsers = ({
  from,
  to,
  softwareId,
}: {
  from?: string
  to?: string
  softwareId?: string
}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryActiveUsers(softwareId),
    queryFn: () => svc.getSoftwareDetailActiveUsers({from, to, softwareId}),
    enabled: !!softwareId,
  })
  return {
    users: resp.data || [],
    ...resp,
  }
}

export const useGetSoftwareDetailUsersDept = ({softwareId}) => {
  const resp = useQuery({
    queryKey: softwareKeys.summaryUsersDept(softwareId),
    queryFn: () => svc.getSoftwareDetailUsersDept({softwareId}),
    enabled: !!softwareId,
  })
  return {
    users: resp.data || [],
    ...resp,
  }
}

export const useGetSoftwareDetailUsers = (
  query: SoftwareDetailUsersListQueries,
  softwareId: string,
) => {
  const res = useQuery({
    queryKey: softwareKeys.detailUsers(softwareId, query),
    queryFn: () => userSvc.getSoftwareDetailsUsers(query, softwareId),
  })

  return {
    users: res.data?.data ?? [],
    fetchingUsers: res.isLoading,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetSoftwareDetailUserFilters = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.detailUsersFilters(softwareId),
    queryFn: () => userSvc.getSoftwareDetailUserFilters(softwareId),
    enabled: !!softwareId,
  })

  return {
    filters: res.data || [],
    fetchingFilters: res.isPending,
    isErrorFilters: res.isError,
  }
}

export const useGetSoftwareLicenseDetails = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.license(softwareId),
    queryFn: () => licenseSvc.getSoftwareLicenseDetails(softwareId),
  })
  return {
    licenseDetails: res?.data?.data,
    isLoading: res.isPending,
    isError: res.isError,
  }
}

export const useUpdateSoftwareLicenseDetails = (softwareId: string) => {
  return useMutate(
    licenseSvc.updateSoftwareLicenseDetails,
    softwareKeys.license(softwareId),
    SOFTWARE_TRACKING_TOASTS.updateSoftwareLicenseDetails,
  )
}

export const useUpdateSoftwareDetails = (softwareId: string) => {
  return useMutate(
    svc.updateSoftwareDetails,
    softwareKeys.detail(softwareId),
    SOFTWARE_TRACKING_TOASTS.updateSoftwareDetails,
  )
}

export const useSoftwareCategories = () => {
  const res = useQuery({queryKey: softwareKeys.category(), queryFn: svc.getSoftwareCategories})
  return {
    categories: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useCreateManualSoftware = (queryKey: QueryKey) => {
  return useMutate(svc.createManualSoftware, queryKey)
}

export const useEditManualSoftware = (queryKey: QueryKey) => {
  return useMutate(svc.editManualSoftware, queryKey)
}

export const useSoftwareTeamList = (search?: string) => {
  const res = useQuery({
    queryKey: ['software', 'all-users'],
    queryFn: () => userSvc._getTeamList(search),
  })

  return {users: res.data, isLoading: res.isPending}
}

// prov/deprov

// get provision users
export const useGetSoftwareProvisionUsers = (
  query: SoftwareProvisionQueries,
  softwareId: string,
) => {
  const res = useInfiniteQuery({
    queryKey: [...softwareKeys.provisionUsers(softwareId, query)],
    queryFn: ({pageParam}) => userSvc.getProvisionUsers(query, softwareId, pageParam),
    enabled: !!softwareId,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const users = res?.data?.pages?.map(page => page?.data).flat()
  return {
    users: users ?? [],
    isFetching: res.isFetching,
    isError: res.isError,
    metaData: res?.data?.pages?.[0]?.meta_data,
    fetchNextPage: res.fetchNextPage,
    isFetchingNextPage: res.isFetchingNextPage,
  }
}

export const useGetSoftwareProvisionSelectedUsers = (softwareId: string, selectedIds?: string) => {
  const res = useQuery({
    queryKey: [...softwareKeys.provisionSelectedUsers(softwareId), selectedIds],
    queryFn: () => userSvc.getProvisionSelectedUsers(softwareId, selectedIds),
    enabled: !!softwareId,
  })

  return {
    selected: res.data ?? [],
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetSoftwareProvisionUsersFilters = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.provisionUsersFilters(softwareId),
    queryFn: () => userSvc.getProvisionUsersFilters(softwareId),
    enabled: !!softwareId,
  })

  return {
    filters: res.data || [],
    isPending: res.isPending,
    isError: res.isError,
  }
}

// mutate provision users
export const useProvisionUsers = (
  id: string,
  payload: SoftwareProvDeprovRequest[],
  isHybridSoftware?: boolean,
) => {
  return useMutate(
    () => userSvc.provisionUsers(id, payload, isHybridSoftware),
    softwareKeys.detailUsers(id),
    SOFTWARE_TRACKING_TOASTS.provision,
  )
}

export const useDeprovisionUsers = (id: string, name?: string, isHybridSoftware?: boolean) => {
  return useMutate(
    (payload: any) => userSvc.deprovisionUsers(id, payload, isHybridSoftware),
    softwareKeys.detailUsers(id),
    SOFTWARE_TRACKING_TOASTS.deprovision(name),
  )
}

export const useGetSoftwareProvisionConfig = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.provisionConfig(softwareId),
    queryFn: () => userSvc.getProvisionConfig(softwareId),
    enabled: !!softwareId,
  })

  return {
    provConfig: res.data ?? [],
    isProvConfigPending: res.isPending,
    isProvConfigError: res.isError,
  }
}

export const useGetSoftwareDeprovisionConfig = (softwareId: string, softwareType?: string) => {
  const res = useQuery({
    queryKey: softwareKeys.deprovisionConfig(softwareId),
    queryFn: () => userSvc.getDeprovisionConfig(softwareId),
    enabled: !!softwareId,
  })
  return {
    deprovConfig: res.data ?? {},
    isDeprovConfigPending: res.isPending,
    isDeprovConfigError: res.isError,
  }
}

export const useGetSoftwareConfig = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.utilConfig(softwareId),
    queryFn: () => utilSvc.getSoftwareConfig(softwareId),
    enabled: !!softwareId,
  })
  return {
    config: res.data,
    isConfigPending: res.isPending,
    isConfigError: res.isError,
  }
}

export const useBulkUploadSaaS = (id: string, queryIdToInvalidate?: QueryKey) => {
  return useMutate(
    userSvc.bulkUploadUsers,
    queryIdToInvalidate,
    SOFTWARE_TRACKING_TOASTS.bulkUpload,
  )
}

export const useGetDefaultAssignmentRules = (softwareId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.defaultAssignmentRules(softwareId),
    queryFn: () => svc.getDefaultAssignmentRules(softwareId),
  })

  return {
    defaultAssignmentRules: res.data?.data,
    isLoading: res.isPending,
    isError: res.isError,
  }
}

export const useAddDefaultAssignmentRules = (softwareId: string) => {
  return useMutate(
    svc.addDefaultAssignmentRules,
    softwareKeys.defaultAssignmentRules(softwareId),
    SOFTWARE_TRACKING_TOASTS.addDefaultAssignmentRules,
  )
}

// prov/deprov end

// google group
export const useGetSoftwareGoogleGroups = (query: GoogleGroupsQueries) => {
  const res = useQuery({
    queryKey: softwareKeys.googleGroups(query),
    queryFn: () => svc.getGoogleGroups(query),
  })

  return {
    groups: res.data?.data ?? [],
    metaData: res.data?.meta_data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetSoftwareGoogleGroupsFilters = () => {
  const res = useQuery({
    queryKey: softwareKeys.googleGroupsFilters,
    queryFn: svc.getGoogleGroupsFilters,
  })
  return {filters: res.data, isPendingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetSoftwareGoogleGroupUsers = (query: GoogleGroupUsersQueries, groupId: string) => {
  const res = useQuery({
    queryKey: softwareKeys.googleGroupUsers(query, groupId),
    queryFn: () => svc.getGoogleGroupUsers(query, groupId),
  })

  return {
    users: res.data ?? [],
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetSoftwareGoogleUserGroups = (workEmail: string) => {
  const res = useQuery({
    queryKey: softwareKeys.googleUserGroups(workEmail),
    queryFn: () => svc.getUserGoogleGroups(workEmail),
  })

  return {
    groups: res.data ?? [],
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetSoftwareGoogleUserGroupsRemaining = (workEmail: string) => {
  const res = useQuery({
    queryKey: softwareKeys.googleUserGroupsRemaining(workEmail),
    queryFn: () => svc.getUserRemainingGoogleGroups(workEmail),
  })

  return {
    groupsRemaining: res.data ?? [],
    isPendingRemaining: res.isPending,
    isErrorRemaining: res.isError,
  }
}

export const useGetSoftwareGoogleGroupAllUsers = (query: GoogleGroupAllUsersQueries) => {
  const res = useQuery({
    queryKey: softwareKeys.googleGroupAllUsers(query),
    queryFn: () => svc.getGoogleGroupAllUsers(query),
  })

  return {
    users: res.data?.data ?? [],
    isPending: res.isPending,
    isError: res.isError,
    metaData: res.data?.meta_data,
  }
}

export const useAddUsersToGoogleGroups = () => {
  return useMutate(
    (payload: {user_emails: string[]; group_ids: string[]}) =>
      svc.addOrRemoveUsersToGoogleGroups(payload, 'add'),
    softwareKeys.googleGroupsBase(),
    SOFTWARE_TRACKING_TOASTS.addUsersToGoogleGroup,
  )
}

export const useRemoveUsersFromGoogleGroups = () => {
  return useMutate(
    (payload: {user_emails: string[]; group_ids: string[]}) =>
      svc.addOrRemoveUsersToGoogleGroups(payload, 'remove'),
    softwareKeys.googleGroupsBase(),
    SOFTWARE_TRACKING_TOASTS.removeUsersToGoogleGroup,
  )
}
// google group end
