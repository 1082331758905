import * as React from 'react'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import classes from './styles.module.css'
import {format, isToday, isYesterday} from 'date-fns'
import {Link} from 'react-router-dom'
import {
  BUTTON_V2_SIZE,
  BUTTON_V2_VARIANT,
  ButtonV2,
  EmptyState,
  Loader,
  ModalV2,
  SearchV2,
  SVG,
  Tooltip,
  useDebounce,
} from '@hybr1d-tech/charizard'
import {useGetAllNotifications} from 'app/services/notifications/notifications.data'
import {NOTIFICATIONS_ACTION_TYPES, useNotificationsStore} from 'app/store/notifications'
import {useLayoutActions} from 'app/store/layout'
import {ICONS} from 'app/utils/constants/icon'
import {
  INotification,
  INotificationGroup,
  NOTIFICATION_TIME,
  NOTIFICATION_TYPE,
} from 'types/notifications'

export const flattenNotifications = (
  notifications: INotificationGroup | undefined | null,
): INotification[] => {
  if (!notifications) return []
  return Object.keys(notifications)
    .map(key => notifications[key].map((notif: INotification) => notif))
    .flat()
}

interface NotificationProps {
  fetchNextPage: () => void
  searchEmptyIcon?: string
  searchEmptyMainText?: string
  searchEmptyDescription?: string
  handlePopover?: (e) => void
  isModal?: boolean
  onModalOpen?: () => void
  notifications?: INotificationGroup
  fetchingNotifications?: boolean
  response?: any
}

export default function Notifications({
  fetchNextPage,
  searchEmptyIcon,
  searchEmptyMainText,
  searchEmptyDescription,
  handlePopover,
  isModal = false,
  onModalOpen,
  notifications,
  response,
  fetchingNotifications = true,
}: NotificationProps) {
  const handleOpenModal = e => {
    e.stopPropagation()
    onModalOpen && onModalOpen()
    handlePopover && handlePopover(e)
  }

  const flattened = React.useMemo(() => flattenNotifications(notifications), [notifications])

  return (
    <div
      className={clsx(classes.notifications, isModal && classes.isModal)}
      style={{height: '30vh'}}
    >
      {fetchingNotifications ? (
        <Loader containerStyle={{height: '100%'}} />
      ) : flattened.length === 0 ? (
        <EmptyState
          icon={searchEmptyIcon ?? ICONS.sunset}
          title={searchEmptyMainText ?? 'No notifications arrived yet'}
          desc={
            searchEmptyDescription ??
            'Make sure you enable notifications in your browser to not to miss any upcoming events'
          }
        />
      ) : (
        <>
          <InfiniteScroll
            dataLength={flattened.length}
            next={fetchNextPage!}
            hasMore={flattened.length < (response?.meta_data?.total_items ?? 0)}
            loader={<Loader />}
            height={'30vh'}
            className={clsx(classes.infiniteScroll, isModal && classes.modalInfiniteScroll)}
          >
            {Object.keys(notifications ?? {}).map((key, index) => (
              <div key={key} className={classes.notificationContainer}>
                <div className={classes.heading}>
                  <p className={classes.key}>{NOTIFICATION_TIME[key] ?? key}</p>
                  {index === 0 && !isModal && (
                    <ButtonV2
                      size={BUTTON_V2_SIZE.SMALL}
                      variant={BUTTON_V2_VARIANT.LINK}
                      onClick={handleOpenModal}
                      customStyles={{padding: 0}}
                    >
                      See all
                    </ButtonV2>
                  )}
                </div>
                <div className={classes.body}>
                  {notifications?.[key].map((notif: INotification) => (
                    <div className={classes.notification} key={notif.id}>
                      <div className={classes.leftContainer}>
                        <div
                          className={classes.iconContainer}
                          style={{background: notifIconMapping[notif.type].bgColor}}
                        >
                          <SVG path={notifIconMapping[notif.type].icon} width={18} height={18} />
                        </div>
                        <div className={classes.notifDetails}>
                          <p className={classes.description}>{notif.description}</p>
                          <Tooltip>
                            <Tooltip.Trigger>
                              <span className={classes.createdAt}>
                                {getNotifFormattedDate(notif.created_at)}
                              </span>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                              {getNotifPopoverDateTimeFormat(notif.created_at)}
                            </Tooltip.Content>
                          </Tooltip>
                        </div>
                      </div>
                      {notif.redirection_url && (
                        <Link to={notif.redirection_url} className={classes.externalLink}>
                          <SVG
                            path={ICONS.open}
                            svgClassName={classes.externalLinkSvg}
                            customSvgStyles={{marginInline: '0 !important'}}
                          />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {!isModal && (
              <ButtonV2
                size={BUTTON_V2_SIZE.SMALL}
                variant={BUTTON_V2_VARIANT.LINK}
                onClick={handleOpenModal}
                customStyles={{padding: 0, margin: 'auto'}}
              >
                See all notifications
              </ButtonV2>
            )}
          </InfiniteScroll>
        </>
      )}
    </div>
  )
}

export const NotificationsModal = ({isOpen, onClose, onPopoverClose}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const {resetMini} = useLayoutActions()
  const dispatch = useNotificationsStore(state => state.allNotificationsDispatch)
  const stringifiedQuery = useNotificationsStore(state => state.stringifiedAllNotificationsQuery)
  const {notifications, fetchingNotifications, fetchNextPage, response} =
    useGetAllNotifications(stringifiedQuery)
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  React.useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined)
      dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.SEARCH,
        payload: searchTerm,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  return (
    <ModalV2
      showBackdrop
      title="Notifications"
      isOpen={isOpen}
      onClose={() => {
        onClose()
        onPopoverClose()
        resetMini()
      }}
      footerButtons={[]}
    >
      <div style={{height: '50px', paddingBottom: '10px'}} onClick={e => e.preventDefault()}>
        <SearchV2
          id="notifications-search"
          search={searchTerm}
          setSearch={setSearchTerm}
          placeholder="Search"
          inputGroupProps={{style: {width: '100%'}}}
        />
      </div>
      <Notifications
        fetchNextPage={fetchNextPage}
        isModal={true}
        notifications={notifications}
        fetchingNotifications={fetchingNotifications}
        response={response}
        searchEmptyIcon={ICONS.bookOpenSearch}
        searchEmptyMainText="No search results found"
        searchEmptyDescription="We could not find any notifications in past 3 months.
        You can try searching by type, date or employee name"
      />
    </ModalV2>
  )
}

export const notifIconMapping = {
  [NOTIFICATION_TYPE.WEBSTORE]: {
    icon: ICONS.shoppingCart,
    bgColor: '#D7EEF4',
    color: '#76C5D6',
  },
  [NOTIFICATION_TYPE.INVENTORY]: {
    icon: ICONS.laptop2,
    bgColor: '#F7DFF0',
    color: '#E189C8',
  },
  [NOTIFICATION_TYPE.TIME_AWAY]: {
    icon: ICONS.calendar,
    bgColor: '#F5F2DB',
    color: '#D6CC71',
  },
  [NOTIFICATION_TYPE.TASKS]: {
    icon: ICONS.fileCheck,
    bgColor: '#E3E9FF',
    color: '#849AEB',
  },
  [NOTIFICATION_TYPE.INTEGRATIONS]: {
    icon: ICONS.fileCheck,
    bgColor: '#E3E9FF',
    color: '#849AEB',
  },
}

// Hack for getting date out of datetime
export const getNotifDate = (dateTime: string) => {
  const date = dateTime.split('T')[0]
  return date
}

export const getNotifFormattedDate = (dateTime: string) => {
  const stringToDate = new Date(dateTime)

  const date = getNotifDate(dateTime)
  const today = isToday(new Date(date))
  const yesterday = isYesterday(new Date(date))

  if (!(today || yesterday)) {
    return format(stringToDate, 'do MMM yyyy')
  }

  if (today) return 'today'
  if (yesterday) return 'yesterday'
}

export const getNotifPopoverDateTimeFormat = (date: string) => {
  return format(new Date(date), "dd MMMM yyyy 'at' p")
}
