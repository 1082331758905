import * as XLSX from 'xlsx'
import {useMutate} from 'app/utils/hooks/useMutate'
import {UIService} from './ui.service'
import {getMimeType} from 'app/utils/helper/download'
import {type QueryKey, useQuery} from '@tanstack/react-query'
import {type TableNameValueString, getTableNameInfo} from 'app/utils/helper/table'
import type {DataRecord, FileFormat} from 'types/ui'

const svc = new UIService()

export const useGetTableCustomCols = (name: string, queryKey) => {
  const res = useQuery({
    queryKey,
    queryFn: () => svc.getTableCols(name),
  })
  return {
    tableColumns: res.data,
    isPendingTableColumns: res.isPending,
    isErrorTableColumns: res.isError,
  }
}
export const useSaveTableCustomCols = (name: string, queryKey: QueryKey) => {
  return useMutate(columns => svc.saveTableCols(columns, name), queryKey)
}

export const useExportTableData = (
  tableNameValue: TableNameValueString,
  queryKey: QueryKey,
  query: {filters: any; search?: any; sort_by?: any; sort_order?: any},
  extraPayload: any = {},
) => {
  const tableInfo = getTableNameInfo(tableNameValue)

  const {mutateAsync, isPending, isError, status} = useMutate(
    ({
      filters,
      type,
      search,
      sort_by,
      sort_order,
    }: {
      filters: any
      type: string
      search?: any
      sort_by?: any
      sort_order?: any
    }) =>
      svc.exportTableData(tableNameValue, filters, type, extraPayload, search, sort_by, sort_order),
    queryKey,
  )

  const exportData = async (type: FileFormat) => {
    try {
      const result = await mutateAsync({
        filters: query.filters,
        type,
        search: query.search,
        sort_by: query.sort_by,
        sort_order: query.sort_order,
      })

      if (result) {
        handleFileDownload(result, tableInfo?.label || tableNameValue, type)
      } else {
        console.error('Export failed')
      }
    } catch (error) {
      console.error('Export failed:', error)
    }
  }

  return {
    exportData,
    isPendingExport: isPending,
    isErrorExport: isError,
    exportStatus: status,
  }
}

const handleFileDownload = (data: any, fileName: string, format: FileFormat) => {
  if (format === 'xlsx') {
    downloadAsXlsx(data, fileName)
  } else {
    const blob = new Blob([data], {type: getMimeType(format)})
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `${fileName}.${format}`
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}

const downloadAsXlsx = (data: DataRecord, fileName: string) => {
  const formattedData = convertDataToSheetFormat(data)
  const workbook = XLSX.utils.book_new()
  const sheet = XLSX.utils.aoa_to_sheet(formattedData)

  XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1')
  handleXlsxFileDownload(workbook, fileName)
}

const convertDataToSheetFormat = (data: DataRecord): Array<Array<string | number>> => {
  const rows: Array<Array<string | number>> = []

  const ref = data['!ref']
  if (typeof ref !== 'string') {
    return rows
  }

  const range = XLSX.utils.decode_range(ref)
  const headers: Array<string> = []

  for (let col = range.s.c; col <= range.e.c; col++) {
    const headerKey = XLSX.utils.encode_cell({r: range.s.r, c: col})
    headers.push(data[headerKey]?.v !== undefined ? String(data[headerKey].v) : '')
  }
  rows.push(headers)

  for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
    const row: Array<string | number> = []
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellKey = XLSX.utils.encode_cell({r: rowNum, c: col})
      const cellValue = data[cellKey]?.v
      row.push(cellValue === null || cellValue === undefined ? '' : String(cellValue))
    }
    rows.push(row)
  }

  return rows
}

const handleXlsxFileDownload = (workbook: XLSX.WorkBook, fileName: string) => {
  const arrayBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${fileName}.xlsx`
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(url)
  document.body.removeChild(a)
}
