import {ICONS} from 'app/utils/constants/icon'
import {Edge, Node, getIncomers} from 'reactflow'
import {TRIGGER_EXECUTION_STATUS} from 'types/workflows'
import {nodeSpace} from 'app/utils/helper/create-workflow'
import {CUSTOM_NODE_TYPE} from 'app/modules/workflow/utils/enum'

export const getNodeStatusTooltipInfo = (status: TRIGGER_EXECUTION_STATUS) => {
  switch (status) {
    case TRIGGER_EXECUTION_STATUS.SUCCESSFUL:
      return {
        src: ICONS.checkCircle,
        variant: 'success',
        message: 'This node executed successfully',
      }
    case TRIGGER_EXECUTION_STATUS.IN_PROGRESS:
      return {
        src: ICONS.hourglass2Half,
        variant: 'warning',
        message: 'Node execution in progress',
      }
    case TRIGGER_EXECUTION_STATUS.FAILED:
      return {
        src: ICONS.alertCircle,
        variant: 'danger',
        message: 'Node execution failed',
      }
    case TRIGGER_EXECUTION_STATUS.NOT_EXECUTED:
      return {
        src: ICONS.clockFlash,
        variant: 'neutral',
        message: 'Yet to execute',
      }
    case TRIGGER_EXECUTION_STATUS.NOT_TO_BE_EXECUTED:
      return {
        src: ICONS.clockFlash,
        variant: 'neutral',
        message: 'Not executed due to condition not matched',
      }
    case TRIGGER_EXECUTION_STATUS.CANCELLED:
      return {
        src: ICONS.closeCircle,
        variant: 'danger',
        message: 'Execution is stopped manually',
      }

    default:
      return {
        src: ICONS.checkCircle,
        variant: 'info',
        message: 'Node execution type cant be found',
      }
  }
}

export const removeAddEndNodeForView = (
  nodes: Node[],
  edges: Edge[],
): {edges: Edge[]; nodes: Node[]} => {
  const allAddEndNode = nodes.filter(node => node.type === CUSTOM_NODE_TYPE.ADD_END_NODE)
  let filteredNodes = nodes.filter(node => node.type !== CUSTOM_NODE_TYPE.ADD_END_NODE)
  filteredNodes = filteredNodes.map(node => {
    if (node.type === CUSTOM_NODE_TYPE.END_NODE) {
      return {
        ...node,
        position: {
          ...node.position,
          y: node.position.y - nodeSpace,
        },
      }
    }
    return node
  })
  let newEdges = [...edges]
  allAddEndNode.forEach(node => {
    let incomer = getIncomers(node, nodes, edges)
    newEdges = newEdges.map(edge => {
      if (edge.source === node.id) {
        return {
          ...edge,
          source: incomer[0].id,
        }
      }
      return edge
    })
  })

  return {nodes: filteredNodes, edges: newEdges}
}
