import * as React from 'react'
import classes from './styles.module.css'
import Notification from './Notification'
import {Accordion, Loader, ModalV2} from '@hybr1d-tech/charizard'
import {useGetNotificationPreferences} from 'app/services/notifications/notifications.data'
import type {INotificationPreferenceData} from 'types/notifications'

interface NotificationPreferencesModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function NotificationPreferencesModal({
  isOpen,
  onClose,
}: NotificationPreferencesModalProps) {
  const {preferences, isLoading} = useGetNotificationPreferences()
  return (
    <ModalV2
      showBackdrop
      title="Notification Preferences"
      footerButtons={[]}
      isOpen={isOpen}
      onClose={onClose}
      customModalClasses={classes.modalCustom}
    >
      <div
        className={classes.notificationPreferencesContainer}
        onMouseEnter={e => e.stopPropagation()}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Accordion customClasses={classes.accordionRoot}>
            {preferences?.map((pref: INotificationPreferenceData) => (
              <React.Fragment key={pref.title}>
                <Notification pref={pref} />
              </React.Fragment>
            ))}
          </Accordion>
        )}
      </div>
    </ModalV2>
  )
}
