import {apiAxios} from '../../configs/axios'
import {sanitizeObj} from 'app/utils'
import {TIMEZONE} from 'app/utils/helper'
import type {TableCustomColumns} from 'types/ui'

export class UIService {
  async getTableCols(name: string): Promise<TableCustomColumns> {
    const {data} = await apiAxios.get(`/charizard/table/custom-columns/${name}`)
    return data
  }

  async saveTableCols(columns, name: string): Promise<{message: string; success: boolean}> {
    const {data} = await apiAxios.post(`/charizard/table/custom-columns/${name}`, {
      checked_state: columns,
    })
    return data
  }
  async exportTableData(
    name: string,
    filters: object,
    type: string,
    extraPayload?: object,
    search?: any,
    sort_by?: any,
    sort_order?: any,
  ): Promise<any> {
    const sanitizedFilters = sanitizeObj(filters, {
      allowEmptyStr: false,
      convertEmptyStrToNull: false,
    })

    const {data} = await apiAxios.post(`/bulk-download/${name}`, {
      ...sanitizedFilters,
      ...extraPayload,
      file_type: type,
      local_timezone: TIMEZONE,
      search: search ? search : undefined,
      sort_by: sort_by ? sort_by : undefined,
      sort_order: sort_order ? sort_order : undefined,
    })
    return data
  }
}
