import * as React from 'react'
import MasterLayout from 'hybrid-ui/layout/MasterLayout'
import SettingsRouter from 'app/modules/settings/SettingsRouter'
import {Route, Routes, Navigate, useLocation, useNavigate} from 'react-router-dom'
import {useModules} from 'app/utils/hooks/use-modules'
import {DASHBOARD_SUMMARY, NOT_FOUND} from './getters'
import {getModuleBasedRouteAccess, getRoleBasedRouteAccess} from './authorized-routes'
import {useModuleControl} from './module-control'
import {SuspensedView, lazyRetry} from './utils'
import {useCurrentUser} from 'app/store/auth'

const DashboardRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard/DashboardRouter'),
    'dashboard',
  ),
)

const TeamRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "team" */ '../modules/team'), 'team'),
)

const OrdersRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "orders" */ '../modules/orders/OrdersRouter'),
    'order',
  ),
)

const ProductRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "products" */ '../modules/products/ProductRouter'),
    'products',
  ),
)

const InventoryRouter = React.lazy(
  () => import(/* webpackChunkName: "inventory" */ '../modules/inventory'),
)

const ReportsRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "reports" */ '../modules/reports'), 'reports'),
)

const TasksRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "tasks" */ '../modules/tasks'), 'tasks'),
)

const ChecklistRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "tasks" */ '../modules/checklist'), 'checklists'),
)

const WorkflowsRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "workflows" */ '../modules/workflow/WorkflowRouter'),
    'workflows',
  ),
)

const AppStoreRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "app-store" */ '../modules/app-store/AppStoreRouter'),
    'app-store',
  ),
)

const ITSupportRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "it-support" */ '../modules/it-support-v2/ITSupportRouter'),
    'it-support',
  ),
)

const MDMRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "mdm" */ '../modules/mdm'), 'mdm'),
)

const DocumentRouter = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "document" */ '../modules/document'), 'document'),
)

const SoftwareRouter = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "software-tracking" */ '../modules/software'),
    'software',
  ),
)

const AttendanceRouter = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "attendance-management" */ '../modules/attendance-management/AttendanceRouter'
      ),
    'attendance-management',
  ),
)

const LeaveRouter = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "leave-management" */ '../modules/leave-management/LeaveRouter'),
    'leave-management',
  ),
)

const ZenAllianceRouter = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "zen-alliance" */ 'app/modules/zen-alliance/ZenAllianceRouter'),
    'zen-alliance',
  ),
)
export default function PrivateRoutes() {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const {hasAccess} = useModuleControl(location.pathname)
  const {fetchingModules} = useModules()

  React.useEffect(() => {
    getRoleBasedRouteAccess(currentUser?.role, navigate, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  React.useEffect(() => {
    if (fetchingModules) return

    getModuleBasedRouteAccess(hasAccess, navigate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingModules, location.pathname])

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to dashboard after success login/registration */}
          <Route path="auth/*" element={<Navigate to={DASHBOARD_SUMMARY} />} />

          <Route
            path="dashboard/*"
            element={
              <SuspensedView>
                <DashboardRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/team/*"
            element={
              <SuspensedView>
                <TeamRouter />
              </SuspensedView>
            }
          />

          <Route
            path="people/document/*"
            element={
              <SuspensedView>
                <DocumentRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/leave/*"
            element={
              <SuspensedView>
                <LeaveRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/attendance/*"
            element={
              <SuspensedView>
                <AttendanceRouter />
              </SuspensedView>
            }
          />

          <Route
            path="devices/inventory/*"
            element={
              <SuspensedView>
                <InventoryRouter />
              </SuspensedView>
            }
          />

          <Route
            path="tools/checklists/*"
            element={
              <SuspensedView>
                <ChecklistRouter />
              </SuspensedView>
            }
          />

          <Route
            path="devices/orders/*"
            element={
              <SuspensedView>
                <OrdersRouter />
              </SuspensedView>
            }
          />

          <Route
            path="devices/products/*"
            element={
              <SuspensedView>
                <ProductRouter />
              </SuspensedView>
            }
          />

          <Route
            path="tools/tasks/*"
            element={
              <SuspensedView>
                <TasksRouter />
              </SuspensedView>
            }
          />

          <Route
            path="tools/workflows/*"
            element={
              <SuspensedView>
                <WorkflowsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="tools/reports/*"
            element={
              <SuspensedView>
                <ReportsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="software/it-support/*"
            element={
              <SuspensedView>
                <ITSupportRouter />
              </SuspensedView>
            }
          />
          <Route
            path="software/tracking/*"
            element={
              <SuspensedView>
                <SoftwareRouter />
              </SuspensedView>
            }
          />
          <Route
            path="devices/mdm/*"
            element={
              <SuspensedView>
                <MDMRouter />
              </SuspensedView>
            }
          />
          <Route
            path="main-settings/*"
            element={
              <SuspensedView>
                <SettingsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="app-store/*"
            element={
              <SuspensedView>
                <AppStoreRouter />
              </SuspensedView>
            }
          />
          <Route
            path="tools/alliance/*"
            element={
              <SuspensedView>
                <ZenAllianceRouter />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to={NOT_FOUND} />} />
        </Route>
      </Routes>
    </>
  )
}
