export const SOFTWARE_TRACKING_TOASTS = {
  addSoftwareTracking: name =>
    `Successfully added manual software tracking ${name ? 'for ' + name : ''}`,
  updateSoftwareTracking: name =>
    `Successfully updated software tracking ${name ? 'for ' + name : ''}`,
  archiveSoftwareTracking: 'Successfully archived software tracking',
  unarchiveSoftwareTracking: 'Successfully unarchived software tracking',
  deleteSoftwareTracking: 'Successfully deleted software tracking',
  addSoftwareLicense: 'Successfully added software license',
  updateSoftwareLicense: 'Successfully updated software license',
  deleteSoftwareLicense: 'Successfully removed manual software',
  docsAddedSuccessfully: 'Successfully added document',
  docsRemovedSuccessfully: 'Successfully removed document',
  syncedSuccessfully: 'Successfully synced software',
  updateSoftwareLicenseDetails: 'Successfully updated software license details',
  updateSoftwareDetails: 'Successfully updated software details',
  provision: `Successfully provisioned users`,
  deprovision: (name?: string) => `Successfully de-provisioned ${name || 'user'}`,
  bulkUpload: `Successfully bulk uploaded users to software`,
  addDefaultAssignmentRules: 'Successfully updated default assignment rules',
  addUsersToGoogleGroup: 'Successfully added users to google groups',
  removeUsersToGoogleGroup: 'Successfully removed users from google group',
}

export const INV_TOASTS = {
  addInv: 'Successfully added inventory',
  editTag: 'Successfully updated tag',
  updateInvFinance: 'Successfully updated inventory finance details',
  archivedSuccessfully: 'Successfully archived inventory',
  deallocateInv: 'Successfully deallocated inventory',
  updateProductSpecs: 'Successfully updated product specifications',
  updateAdditionalInfo: "Successfully updated product's additional info",
  changeStatus: 'Successfully updated inventory status',
  unArchiveInventory: 'Successfully unarchived inventory',
  deleteInventory: 'Successfully deleted inventory',
  archivedInfoUpdate: 'Successfully updated archived info',
  cancelInventory: (type: 'allocation' | 'deallocation') => `Successfully cancelled ${type}`,
}

export const MDM_TOASTS = {
  syncDeviceDetails: 'Successfully synced device details',
  syncSummary: 'Successfully synced device details',
  updatedPolicy: 'Successfully updated device policies',
  updatedDeviceGroup: 'Successfully updated device group',
  appInstalled: 'Successfully installed application',
  appUninstalled: 'Successfully uninstalled application',
  appActionSuccessful: action => `Successfully done ${action} action`,
}

export const AWAY_TOASTS = {
  createTimeAwayPolicy: 'Successfully created the PTO policy',
  updateTimeAwayPolicy: 'Successfully updated the PTO policy',
  deleteTimeAwayPolicy: 'Successfully deleted PTO policy',
  // deleteTimeAwayPolicy: 'Successfully deleted PTO policy',
}

export const TEAM_TOASTS = {
  activateUser: `Successfully activated user account`,
  inviteUser: `Invitation sent successfully`,
  deleteUser: 'Successfully deleted user profile',
  assignTag: 'Successfully assigned tag(s)',
  updateDepartment: 'Successfully updated department',
  assignRole: 'Successfully assigned role',
  createNewHire: 'Successfully created new hire',
  createReHire: 'Successfully created rehire',
  activate: 'Successfully activated new hire',
}

export const USER_TOASTS = {
  updateProfilePicture: 'Successfully updated profile picture',
}

export const WORKFLOWS_TOASTS = {
  createWorkflow: 'Successfully created workflow',
  updateWorkflow: 'Successfully updated workflow',
  duplicateWorkflow: 'Successfully duplicated workflow',
  workflowAction: 'Successfully updated workflow',
  workflowNodeUpdate: 'Successfully updated the node',
  stopWorkflowTriggerExecution: 'Successfully stopped workflow trigger execution',
  deleteWorkflow: 'Successfully deleted workflow',
  retryNodeExecution: 'Successfully re-run node execution',
  cancelTriggerExecution: 'Successfully cancelled execution',
}

export const FORM_TOASTS = {
  formDataDownload: 'Successfully downloaded filled data',
  deleteForm: 'Successfully deleted form',
  updateForm: 'Successfully updated form',
}

export const TASK_TOASTS = {
  formApproval: 'Successfully updated approval form',
  submitForm: 'Successfully submitted form',
  SUCCESSFULLY_APPROVED_LEAVE: 'Successfully approved leave',
  SUCCESSFULLY_DECLINED_LEAVE: 'Successfully declined leave',
  SUCCESSFULLY_APPROVED_CANCEL_REQUEST: 'Successfully approved cancel request',
  SUCCESSFULLY_DECLINED_CANCEL_REQUEST: 'Successfully declined cancel request',
  APPROVE_LEAVE_CANCELLATION_FAIL: 'Failed to approve leave cancellation',
  DECLINE_LEAVE_CANCELLATION_FAIL: 'Failed to decline leave cancellation',
}

export const MANAGE_TIME_AWAY = {
  bookTime: 'Successfully booked time away',
}

export const HIRING_TEMPLATE_TOAST = {
  createHiringTemplate: 'Successfully created hiring template',
  deleteHiringTemplate: 'Successfully deleted hiring template',
  updateHiringTemplate: 'Successfully updated hiring template',
}

export const ATTENDANCE_TOAST = {
  extendAttendanceCycle: 'Successfully extended attendance cycle',
  updateApproval: 'Successfully saved default approvers',
  regularizeAttendance: 'Successfully submitted attendance regularization',
}

export const REPORT_TOAST_V2 = {
  folderCreatedSuccessfully: 'Successfully created folder',
  reportCreateSuccessfully: 'Successfully create report',
  movedReportSuccessfully: 'Successfully moved report',
  duplicateReportSuccessfully: 'Successfully duplicated report',
  updateReportSuccessfully: 'Successfully updated report',
  deletedReportSuccessfully: 'Successfully deleted report',
  folderUpdatedSuccessfully: 'Successfully updated folder',
  deletedFolderSuccessfully: 'Successfully deleted folder',
  reportSavedSuccessfully: 'Successfully saved report',
}

export const ZEN_ALLIANCE_TOAST = {
  errorInFetchingPartnershipsHeading: 'Uh oh! Failed to fetch partnerships',
  errorInFetchingResellersHeading: 'Uh oh! Failed to fetch resellers',
  errorInFetchingPartnershipsInfo: 'Failed to fetch partnerships. Please try again later.',
  errorInFetchingResellersInfo: 'Failed to fetch resellers. Please try again later.',
}
