import {ICONS} from 'app/utils/constants/icon'
import {WORKFLOW_AUDIT_ACTION, WORKFLOW_AUDIT_ACTION_TYPE, WORKFLOW_STATUS} from 'types/workflows'
import {
  CUSTOM_EDGE_TYPE,
  CUSTOM_NODE_TYPE,
  CustomEdgeTypesType,
  CustomNodeTypesType,
  DRAWER_CONTENT_TYPE,
} from './enum'
import CreateEditAllCustomNodes from '../components/react-workflow/custom-nodes'
import ViewAllCustomNodes from '../pages/workflow-details/pages/workflow-view/components/custom-nodes'
import ExecutionAllCustomNodes from '../pages/workflow-node-execution-view/components/view-react-workflow/custom-nodes'
import AddNodeEdge from '../components/react-workflow/add-node-edge/AddNodeEdge'
import ViewAddNodeEdge from '../pages/workflow-details/pages/workflow-view/components/add-node-edge'
import ExecutionAddNodeEdge from '../pages/workflow-node-execution-view/components/view-react-workflow/add-node-edge'

export const triggerAtOptions = Array.from({length: 24 * 60}, (_, i) => {
  // 24 hours * 60 minutes per hour
  const hour = Math.floor(i / 60)
  const minute = i % 60 // Every minute
  const amPm = hour < 12 || hour === 24 ? 'AM' : 'PM'
  let displayHour: string | number = hour % 12 === 0 ? 12 : hour % 12 // Adjust for 12-hour format
  displayHour = displayHour < 10 ? `0${displayHour}` : displayHour
  const displayMinute = minute < 10 ? `0${minute}` : minute // Ensure minute is two digits
  return {
    label: `${displayHour}:${displayMinute} ${amPm}`,
    value: `${hour < 10 ? '0' + hour : hour}:${displayMinute}`, // Keeping a consistent 24-hour format for value
  }
})

export const getOptionFromValue = (options, value) => {
  return options?.find(option => option.value === value)
}

export const WorkflowTriggerHistorySortMap = {
  completed_on: 'completed_on',
}

export const getActionMeta = (
  action: WORKFLOW_AUDIT_ACTION,
  type: WORKFLOW_AUDIT_ACTION_TYPE,
  nodeType: DRAWER_CONTENT_TYPE,
  status: WORKFLOW_STATUS,
) => {
  if (type === WORKFLOW_AUDIT_ACTION_TYPE.WORKFLOW) {
    switch (action) {
      case WORKFLOW_AUDIT_ACTION.UPDATED:
      case WORKFLOW_AUDIT_ACTION.TRIGGERED:
        return {icon: ICONS.textboxCheck}
      case WORKFLOW_AUDIT_ACTION.MODIFIED:
        return {icon: ICONS.lightningBolt}
      case WORKFLOW_AUDIT_ACTION.ADDED:
        return {icon: ICONS.login2HalfCircle}
      case WORKFLOW_AUDIT_ACTION.REMOVED:
        return {icon: ICONS.logoutHalfCircle}
      case WORKFLOW_AUDIT_ACTION.ACTIVATED:
        return {icon: ICONS.archive}
      case WORKFLOW_AUDIT_ACTION.CREATED:
        return {icon: ICONS.plusCircle}
      case WORKFLOW_AUDIT_ACTION.DISABLED:
        return {icon: ICONS.minusCircle}
      case WORKFLOW_AUDIT_ACTION.EDITED:
        return {icon: ICONS.editPen5}
      default:
        return {icon: ICONS.sidebar.software.software}
    }
  } else if (type === WORKFLOW_AUDIT_ACTION_TYPE.NODE) {
    switch (nodeType) {
      case DRAWER_CONTENT_TYPE.GENESIS_NODE:
        return {icon: ICONS.lightningBolt}
      case DRAWER_CONTENT_TYPE.SAAS_PROVISIONING:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.SAAS_DE_PROVISIONING:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.FORM:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.APPROVAL:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.SAAS_PROVISIONING_FORM:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.SAAS_DE_PROVISIONING_FORM:
        return {icon: ICONS.creditCardFlash}
      case DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE:
        return {icon: ICONS.gitPullPlus}
      case DRAWER_CONTENT_TYPE.MS_GROUP_PROVISIONING:
      case DRAWER_CONTENT_TYPE.MS_PROVISIONING:
      case DRAWER_CONTENT_TYPE.GOOGLE_PROVISIONING:
      case DRAWER_CONTENT_TYPE.GOOGLE_GROUP_PROVISIONING:
        return {icon: ICONS.suitCaseCheck}
      case DRAWER_CONTENT_TYPE.SEND_NOTIFICATION:
        return {icon: ICONS.bell}
      case DRAWER_CONTENT_TYPE.DELAY:
        return {icon: ICONS.clockPlus}
      case DRAWER_CONTENT_TYPE.CREATE_TASK:
        return {icon: ICONS.checklist}
      case DRAWER_CONTENT_TYPE.CHANGE_EMPLOYEE_STATUS:
        return {icon: ICONS.userFlash1}
      case DRAWER_CONTENT_TYPE.MIRADORE:
        return {icon: ICONS.miradore}
      case DRAWER_CONTENT_TYPE.HEXNODE:
        return {icon: ICONS.hexnode}

      //TODO miradore to be added
      default:
        return {icon: ICONS.noteTextReload}
    }
  } else if (type === WORKFLOW_AUDIT_ACTION_TYPE.STATUS) {
    switch (status) {
      case WORKFLOW_STATUS.ACTIVE:
        return {icon: ICONS.link2Angled}
      case WORKFLOW_STATUS.INACTIVE:
        return {icon: ICONS.link2AngledOff}
      case WORKFLOW_STATUS.DRAFT:
        return {icon: ICONS.listBoxEdit}
      case WORKFLOW_STATUS.INCOMPLETE:
        return {icon: ICONS.restricted2Square}
      default:
        return {icon: ICONS.link2Angled}
    }
  }
  return {icon: ICONS.noteTextReload}
}

export const createEditCustomNodeTypes: CustomNodeTypesType = {
  [CUSTOM_NODE_TYPE.GENESIS_NODE]: CreateEditAllCustomNodes.GenesisNode,
  [CUSTOM_NODE_TYPE.ADD_END_NODE]: CreateEditAllCustomNodes.AddEndNode,
  [CUSTOM_NODE_TYPE.MIDDLE_NODE]: CreateEditAllCustomNodes.MiddleNode,
  [CUSTOM_NODE_TYPE.END_NODE]: CreateEditAllCustomNodes.EndNode,
  [CUSTOM_NODE_TYPE.MERGE_NODE]: CreateEditAllCustomNodes.MergeNode,
}

export const createEditCustomEdgeTypes: CustomEdgeTypesType = {
  [CUSTOM_EDGE_TYPE.ADD_NODE_EDGE]: AddNodeEdge,
  [CUSTOM_EDGE_TYPE.YES_EDGE]: AddNodeEdge,
  [CUSTOM_EDGE_TYPE.NO_EDGE]: AddNodeEdge,
}

export const viewOnlyCustomNodeType: CustomNodeTypesType = {
  [CUSTOM_NODE_TYPE.GENESIS_NODE]: ViewAllCustomNodes.GenesisNode,
  [CUSTOM_NODE_TYPE.ADD_END_NODE]: ViewAllCustomNodes.AddEndNode,
  [CUSTOM_NODE_TYPE.MIDDLE_NODE]: ViewAllCustomNodes.MiddleNode,
  [CUSTOM_NODE_TYPE.END_NODE]: ViewAllCustomNodes.EndNode,
  [CUSTOM_NODE_TYPE.MERGE_NODE]: ViewAllCustomNodes.MergeNode,
}

export const viewOnlyCustomEdgeTypes: CustomEdgeTypesType = {
  [CUSTOM_EDGE_TYPE.ADD_NODE_EDGE]: ViewAddNodeEdge,
  [CUSTOM_EDGE_TYPE.YES_EDGE]: ViewAddNodeEdge,
  [CUSTOM_EDGE_TYPE.NO_EDGE]: ViewAddNodeEdge,
}

export const nodeExecutionCustomNodeTypes: CustomNodeTypesType = {
  [CUSTOM_NODE_TYPE.GENESIS_NODE]: ExecutionAllCustomNodes.GenesisNode,
  [CUSTOM_NODE_TYPE.ADD_END_NODE]: ExecutionAllCustomNodes.AddEndNode,
  [CUSTOM_NODE_TYPE.MIDDLE_NODE]: ExecutionAllCustomNodes.MiddleNode,
  [CUSTOM_NODE_TYPE.END_NODE]: ExecutionAllCustomNodes.EndNode,
  [CUSTOM_NODE_TYPE.MERGE_NODE]: ExecutionAllCustomNodes.MergeNode,
}
export const nodeExecutionCustomEdgeTypes: CustomEdgeTypesType = {
  [CUSTOM_EDGE_TYPE.ADD_NODE_EDGE]: ExecutionAddNodeEdge,
  [CUSTOM_EDGE_TYPE.YES_EDGE]: ExecutionAddNodeEdge,
  [CUSTOM_EDGE_TYPE.NO_EDGE]: ExecutionAddNodeEdge,
}
