// * query key factory, always return string[] directly from here
// * naming convention:

import {
  AddInventoryQueries,
  ArchivedInventoryQueries,
  InventoryActivityQueries,
  InventoryAllocationHistoryQueries,
  InventoryQueries,
} from 'app/store/inventory'
import {LeaveQueries} from 'app/store/leaves'
import {PolicyQueries as AttendacePolicyQueries} from 'app/store/attendance'
import {
  MDMDeviceActivityQueries,
  MDMDeviceAssociatedPolicyQueries,
  MDMDeviceInstallAppsQueries,
  MDMDeviceInstalledAppsQueries,
  MDMPolicyQueries,
  MDMQueries,
  MDMUsersQueries,
} from 'app/store/mdm'
import {
  SoftwareActivityQueries,
  SoftwareDetailUsersListQueries,
  SoftwareProvisionQueries,
  SoftwareQueries,
  SoftwareUserListQueries,
  GoogleGroupsQueries,
  GoogleGroupAllUsersQueries,
  GoogleGroupUsersQueries,
} from 'app/store/software'

import {HiringTemplateQueries} from 'types/hiring-template'
import {LeaveActivitiesQueryParams} from 'types/time-away'
import type {SaasProvisionAppQuery} from 'types/workflows'
import {QueryKey} from '@tanstack/react-query'
import {FormQueries} from 'app/store/form'
import { ReportDetailsListQueries } from 'app/store/report'

export const USER_QUERIES = {
  EQUIPMENT_LIST(userId) {
    return `equipment-list-${userId}`
  },
  TEAM_LIST: 'team-list',
}

export const taskKeys = {
  all: ['task'] as const,
  list: (queries?: any) => [...taskKeys.all, 'list', {queries}] as const,
  assignees: (...args: any) => [...taskKeys.all, 'assignees', ...args] as const,
  tasks: () => [...taskKeys.all, 'my-tasks'] as const,
  myTasks: (query?: string) => [...taskKeys.tasks(), 'my-tasks', query] as const,
  details: () => [...taskKeys.all, 'details'] as const,
  detail: (id?: string) => [...taskKeys.details(), id] as const,
  leaveRequest: () => [...taskKeys.all, 'leave-request'] as const,
  cancelLeaveRequest: (key: QueryKey) => [...taskKeys.all, ...key] as const,
  taskComments: (id: string) => [...taskKeys.detail(id), 'comments'] as const,
  formAllRequests: () => [...taskKeys.all, 'form-request'] as const,
  formAllApprovals: () => [...taskKeys.all, 'form-approvals'] as const,
  triggerAllForms: () => [...taskKeys.all, 'trigger-forms'] as const,
  formRequests: (query?: string) => [...taskKeys.formAllRequests(), query] as const,
  formApprovals: (query?: string) => [...taskKeys.formAllApprovals(), query] as const,
  triggerForms: (query?: string) => [...taskKeys.triggerAllForms(), query] as const,
}

export const checklistKeys = {
  all: ['checklist'] as const,
  list: (queries?: any) => [...checklistKeys.all, 'list', {queries}] as const,
  checklistTasks: (id?: string) => [...checklistKeys.all, 'tasks', id] as const,
  assigneeChecklistTasks: (checklistId?: string, assigneeId?: string) =>
    [...checklistKeys.all, 'assignee-tasks', checklistId, assigneeId] as const,
  taskDetails: (id?: string) => [...checklistKeys.all, 'task-details', id] as const,
  recipes: () => [...checklistKeys.all, 'recipes'] as const,
  details: () => [...checklistKeys.all, 'details'] as const,
  detail: (id?: string) => [...checklistKeys.details(), id] as const,
}

export const mdmKeys = {
  all: ['mdm'] as const,
  devices: (queries?: MDMQueries) => [...mdmKeys.all, 'devices', {queries}] as const,
  deviceFilters: () => [...mdmKeys.all, 'device-filters'] as const,
  customColumns: () => [...mdmKeys.all, 'custom-columns'] as const,
  exportData: ['mdm-export-data'] as const,
  usersExportData: ['mdm-users-export-data'] as const,
  usersList: (queries?: MDMUsersQueries) => [...mdmKeys.all, 'users-list', {queries}] as const,
  userFilters: () => [...mdmKeys.all, 'user-filters'] as const,
  deviceDetails: (id?: string) => [...mdmKeys.all, 'device-details', id] as const,
  summaryBase: () => [...mdmKeys.all, 'summary'] as const,
  summary: (query?: any) => [...mdmKeys.all, ...mdmKeys.summaryBase(), ...query] as const,
  summaryApps: () => [...mdmKeys.all, ...mdmKeys.summaryBase(), 'apps'] as const,
  policyBase: () => [...mdmKeys.all, 'policy'] as const,
  policyList: (queries?: MDMPolicyQueries, provider?: string) =>
    [...mdmKeys.policyBase(), 'list', provider, {queries}] as const,
  associatedPolicy: (id?: string, queries?: MDMDeviceAssociatedPolicyQueries) =>
    [...mdmKeys.policyBase(), 'associated', id, {queries}] as const,
  activity: (id?: string, queries?: MDMDeviceActivityQueries) =>
    [...mdmKeys.deviceDetails(id), 'activity', {queries}] as const,
  installedApps: (id?: string, queries?: MDMDeviceInstalledAppsQueries) =>
    [...mdmKeys.deviceDetails(id), 'installed-apps', {queries}] as const,
  availableApps: (queries?: MDMDeviceInstallAppsQueries, ...args) =>
    [...mdmKeys.all, 'available-apps', {queries}, ...args] as const,
  actions: (integration_id?: string, device_id?: string) =>
    [...mdmKeys.all, 'actions', integration_id, device_id] as const,
  providers: () => [...mdmKeys.all, 'providers'] as const,
  deviceConfiguration: (integration_id?: string) =>
    [...mdmKeys.all, 'configuration', integration_id] as const,
}

export const invKeys = {
  // base inv query
  all: ['inventory'] as const,

  // inv list
  list: (queries?: InventoryQueries) => [...invKeys.all, 'list', {queries}] as const,
  customColumns: () => [...invKeys.all, 'custom-columns'] as const,
  exportData: ['inventory-export-data'] as const,
  archiveExportData: ['inventory-archive-export-data'] as const,
  allocationHistoryExportData: ['inventory-allocation-export-data'] as const,

  // inv table filters
  // heuristic, if any mutation should change data/cache, then add the base in the key
  listFilters: () => ['inventory-list-filters'] as const,
  allocationUsersFilters: () => ['inventory-allocation-users-filters'] as const,

  // used as details base, since inv details contains multiple apis, and we might have to invalidate all of them
  details: () => [...invKeys.all, 'details'] as const,

  detail: (id: string) => [...invKeys.details(), id] as const,

  lastDeallocation: (id: string) => [...invKeys.detail(id), 'last-deallocation'] as const,

  // listDetails: (id: string) => [...invKeys.details(), id] as const,

  //  Array item order matters
  activity: (id: string, queries: InventoryActivityQueries) =>
    [...invKeys.detail(id), 'activity', {queries}] as const,

  // building from details page since history is present in inv details
  allocationHistory: (id: string, queries: InventoryAllocationHistoryQueries) =>
    [...invKeys.detail(id), 'allocation-history', {queries}] as const,

  allocationHistoryFilters: ['inventory-allocation-history-filters'] as const,

  // allocation and deallocation
  usersBase: () => [...invKeys.all, 'users'] as const,
  users: (queries?: AddInventoryQueries) => [...invKeys.all, 'users', {queries}] as const,

  product: (id: string) => [...invKeys.detail(id), 'product'] as const,

  finance: (id: string) => [...invKeys.detail(id), 'finance'] as const,

  addCache: () => [...invKeys.all, 'add-inventory-cache'] as const,

  archives: () => [...invKeys.all, 'archive'] as const,

  invProcurementSourceOptions: () => [...invKeys.all, 'procurement-source-options'] as const,
  invProcurementSources: () => [...invKeys.all, 'procurement-sources'] as const,
  productTypes: () => [...invKeys.all, 'product-type'] as const,

  //  archived inv list
  archivedList: (queries?: ArchivedInventoryQueries) =>
    [...invKeys.archives(), 'list', {queries}] as const,

  archivedListFilters: () => [...invKeys.archives(), 'list', 'filters'] as const,
  archiveReason: () => [...invKeys.archives(), 'reason'] as const,
  archiveDetails: (id: string) => [...invKeys.archives(), 'details', id] as const,

  archive: (id: string) => [...invKeys.detail(id), 'archive'] as const,

  summary: () => [...invKeys.all, 'summary'],
  highlights: (date: {from: string; to: string}) => [
    ...invKeys.all,
    'summary',
    'highlights',
    {date},
  ],
  totalSpends: (date: {from: string; to: string}) => [
    ...invKeys.all,
    'summary',
    'total-spends',
    {date},
  ],
  deptSpends: (date: {from: string; to: string}) => [
    ...invKeys.all,
    'summary',
    'department-spends',
    {date},
  ],
  locSpends: (date: {from: string; to: string}) => [
    ...invKeys.all,
    'summary',
    'location-spends',
    {date},
  ],
}

export const userKeys = {
  all: ['user'] as const,
  equipmentList: (userId: string) => [...userKeys.all, 'equipment-list', userId] as const,
  equipments: (userId: string) => [...userKeys.all, 'equipments', userId] as const,
  salaryBreakup: (userId: string) =>
    [...userKeys.all, 'employment', 'salary-breakup', userId] as const,
}

export const utilityKeys = {
  metaInfo: ['meta-info'],
  metaInfoTasks: () => [...utilityKeys.metaInfo, 'meta-info-tasks'],
  metaInfoITSupport: () => [...utilityKeys.metaInfo, 'meta-info-it-support'],
  metaInfoNotifications: () => [...utilityKeys.metaInfo, 'meta-info-notifications'],

  teamList: () => ['team-list'],
}

export const APP_STORE_QUERIES = {
  APP_STORE_APPS: 'app-store-apps',
  ZENADMIN_MODULES: 'hybr1d-modules',
  MODULE_DETAILS: 'module-details',
  GET_MODULE_DETAILS(id) {
    return `module-details-${id}`
  },
  GET_APP_DETAILS(id) {
    return `app-store-details-${id}`
  },
}

export const INTEGRATION_QUERIES = {
  GET_INTEGRATION_DETAILS_QUERY_KEY(app: string) {
    return `${app}-app-store-details`
  },
}

export const ORDER_LIST_QUERIES = {
  ORDER_FILTERS: 'order-filters',
}

export const TIME_AWAY_QUERIES = {
  TIME_AWAY: 'time-away',
  TIME_AWAY_FILTERS: 'time-away-filters',
  SAME_PERIOD_AWAY_EMPLOYEES: 'same-period-away-employees',
  REMAINING_LEAVES: 'remaining-leaves',
  SETTINGS: {
    TIME_AWAY_POLICY: 'time-away-policies',
  },
}

export const timeAwayKeys = {
  // base inv query
  all: ['time-away'] as const,
  list: () => [...timeAwayKeys.all, 'list'] as const,
  awayFilters: ['time-away-filters'] as const,
}

export const DOC_QUERY_KEY = {
  GET_USER_DOC: 'GET_USER_DOC',
  CREATE_USER_DOC: 'CREATE_USER_DOC',
  GET_USER_DOC_TYPE: 'GET_USER_DOC_TYPE',
  CREATE_USER_DOC_TYPE: 'CREATE_USER_DOC_TYPE',
}

export const teamKeys = {
  all: ['team'] as const,
  orgChart: () => [...teamKeys.all, 'org-chart'] as const,
  profileDrawer: id => [...teamKeys.all, 'details', id] as const,
  listFilters: () => [...teamKeys.all, 'list', 'filters'] as const,
  members: (queries?: any) => [...teamKeys.all, 'members', queries] as const,
  membersCount: (queries?: any) => [...teamKeys.all, 'members-count', queries] as const,
  events: (queries?: any) => [...teamKeys.all, 'events', queries] as const,
  customColumns: () => [...teamKeys.all, 'custom-columns'] as const,
  newHireCache: () => [...teamKeys.all, 'new-hire-cache'] as const,
  reHireCache: () => [...teamKeys.all, 're-hire-cache'] as const,
  activateCache: () => [...teamKeys.all, 'activate-cache'] as const,
  exportData: ['team-export-data'] as const,
}

export const checkoutTeamKeys = {
  all: ['checkout-team'] as const,
  team: (queries?: any) => ['checkout', {queries}] as const,
}

export const notificationKeys = {
  all: ['notification'] as const,
  notificationsBase: () => [...notificationKeys.all, 'all'] as const,
  notifications: (query?: string) => [...notificationKeys.notificationsBase(), query] as const,
  preferences: () => [...notificationKeys.all, 'preferences'] as const,
}

export const softwareKeys = {
  all: ['software'] as const,
  customColumns: () => [...softwareKeys.all, 'custom-columns'] as const,
  exportData: ['software-export-data'] as const,
  usersExportData: ['software-users-export-data'] as const,
  detailsUsersExportData: ['software-details-users-export-data'] as const,
  users: (queries?: SoftwareUserListQueries) => [...softwareKeys.all, 'users', {queries}] as const,
  list: (queries?: SoftwareQueries) => [...softwareKeys.all, 'list', {queries}] as const,
  listNoQueries: () => [...softwareKeys.all, 'list'] as const,
  listFilters: () => [...softwareKeys.all, 'list-filters'] as const,
  usersFilters: () => [...softwareKeys.all, 'users-filters'] as const,
  details: () => [...softwareKeys.all, 'details'] as const,
  detail: (id: string) => [...softwareKeys.details(), id] as const,
  detailUsers: (id: string, queries?: SoftwareDetailUsersListQueries) =>
    [...softwareKeys.detail(id), 'users', {queries}] as const,
  detailUsersNoQueries: (id: string) => [...softwareKeys.detail(id), 'users'] as const,
  detailUsersFilters: (id: string) => [...softwareKeys.detail(id), 'users-filters'] as const,
  provisionUsers: (id: string, queries?: SoftwareProvisionQueries) =>
    [...softwareKeys.detail(id), 'provision-users', {queries}] as const,
  provisionSelectedUsers: (id: string) =>
    [...softwareKeys.detail(id), 'provision-selected-users'] as const,
  provisionUsersNoQueries: (id: string) => [...softwareKeys.detail(id), 'provision-users'] as const,
  provisionUsersFilters: (id: string) =>
    [...softwareKeys.detail(id), 'provision-users-filters'] as const,
  license: (id: string) => [...softwareKeys.detail(id), 'license'] as const,
  defaultAssignmentRules: (id: string) =>
    [...softwareKeys.detail(id), 'default-assignment-rules'] as const,
  documents: (id: string) => [...softwareKeys.detail(id), 'documents'] as const,
  activity: (id: string, queries?: SoftwareActivityQueries) =>
    [...softwareKeys.detail(id), 'activity', {queries}] as const,
  category: () => [...softwareKeys.all, 'category'] as const,
  summary: () => [...softwareKeys.all, 'summary'],
  summaryInsights: (softwareId?: string) => [...softwareKeys.summary(), 'insights', softwareId],
  summaryActiveUsers: (softwareId?: string) => [
    ...softwareKeys.summary(),
    'active-users',
    softwareId,
  ],
  summaryUsersDept: (softwareId?: string) => [...softwareKeys.summary(), 'users-dept', softwareId],
  summaryAppsUsage: (softwareId?: string) => [...softwareKeys.summary(), 'apps-usage', softwareId],
  detailsSync: (id: string) => [...softwareKeys.detail(id), 'sync'],
  provisionConfig: (id: string) => [...softwareKeys.detail(id), 'provision-users-config'] as const,
  deprovisionConfig: (id: string) =>
    [...softwareKeys.detail(id), 'deprovision-users-config'] as const,
  utilConfig: (id: string) => [...softwareKeys.detail(id), 'util-config'],
  googleGroupsBase: () => [...softwareKeys.all, 'google-groups'] as const,
  googleGroups: (queries: GoogleGroupsQueries) =>
    [...softwareKeys.googleGroupsBase(), 'list', {queries}] as const,
  googleGroupsNoQueries: () => [...softwareKeys.googleGroupsBase(), 'list'] as const,
  googleGroupsFilters: ['google-groups-filters'] as const,
  googleGroupAllUsers: (queries: GoogleGroupAllUsersQueries) =>
    [...softwareKeys.googleGroupsBase(), {queries}] as const,
  googleGroupUsers: (queries: GoogleGroupUsersQueries, groupId: string) =>
    [...softwareKeys.googleGroupAllUsers(queries), groupId] as const,
  googleUserGroupsBase: () => [...softwareKeys.googleGroupsBase(), 'user-groups'] as const,
  googleUserGroups: (workEmail: string) =>
    [...softwareKeys.googleGroupsBase(), 'user-groups', workEmail] as const,
  googleUserGroupsRemaining: (workEmail: string) =>
    [...softwareKeys.googleGroupsBase(), 'user-groups-remaining', workEmail] as const,
}

export const workflowKeys = {
  all: ['workflows'] as const,
  lists: () => [...workflowKeys.all, 'list'] as const,
  list: (queries?: any) => [...workflowKeys.all, 'list', {queries}] as const,
  listFilters: () => ['workflows-list-filters'] as const,
  customColumns: () => [...workflowKeys.all, 'custom-columns'] as const,
  details: () => [...workflowKeys.all, 'details'] as const,
  detail: (id: string) => [...workflowKeys.details(), id] as const,
  stats: (id: string) => [...workflowKeys.detail(id), 'stats'] as const,
  triggerHistory: (id: string, queries?: any) =>
    [...workflowKeys.detail(id), 'history', {queries}] as const,
  triggerHistoryFilters: () => ['workflows-trigger-history-filters'] as const,
  activity: (id: string, queries?: any) =>
    [...workflowKeys.detail(id), 'activity', {queries}] as const,
  category: () => ['workflows-category'] as const,
  variables: () => ['workflows-variables'] as const,
  emailFormatVariables: () => [...workflowKeys.all, 'email-format'] as const,
  nodeOptions: () => [...workflowKeys.all, 'node-options'] as const,
  companyPersonalizedVariable: () =>
    [...workflowKeys.all, 'company-personalized-variable'] as const,
  emailRecipient: (queries?: any) => [...workflowKeys.all, 'email-recipient', {queries}] as const,
  googleProvisioning: () => [...workflowKeys.all, 'google-provisioning'] as const,
  msProvisioning: () => [...workflowKeys.all, 'microsoft-provisioning'] as const,
  conditionFields: () => [...workflowKeys.all, 'condition-fields'],
  googleGroups: (id: string) => [...workflowKeys.detail(id), 'google-groups'],
  msGroups: (id: string) => [...workflowKeys.detail(id), 'ms-groups'],
  executionDetails: (id: string) => [...workflowKeys.all, id],
}

export const saasProvisionAppsKeys = {
  all: ['saas-provision-apps'] as const,
  list: () => [...saasProvisionAppsKeys.all, 'list'] as const,
  listByQuery: (queries: SaasProvisionAppQuery) =>
    [...saasProvisionAppsKeys.list(), queries] as const,
}

export const authKeys = {
  all: ['auth'] as const,
  incognitoCompanies: (search?: string) =>
    [...authKeys.all, 'incognito-companies', search] as const,
}

export const dashboardKeys = {
  all: ['dashboard'] as const,
  itScore: () => [...authKeys.all, 'it-score'] as const,
  teamStats: () => [...authKeys.all, 'team-stats'] as const,
  invStats: () => [...authKeys.all, 'inv-stats'] as const,
  mdmStats: () => [...authKeys.all, 'mdm-stats'] as const,
  softwareStats: () => [...authKeys.all, 'software-stats'] as const,
  recentOrders: () => [...authKeys.all, 'recent-orders'] as const,
  recentNotifs: (search: string) => [...authKeys.all, 'recent-notifications', search] as const,
}

export const itSupportKeys = {
  all: ['it-support'] as const,
  summary: () => [...itSupportKeys.all, 'summary'],
  highlights: (date: {from?: string; to?: string}, type: string) => [
    ...itSupportKeys.summary(),
    'highlights',
    type,
    {date},
  ],
  ticketStatus: (date: {from?: string; to?: string}) => [
    ...itSupportKeys.summary(),
    'ticket-status',
    {date},
  ],
  ticketCategory: (date: {from?: string; to?: string}) => [
    ...itSupportKeys.summary(),
    'ticket-category',
    {date},
  ],
  ticketCount: (date: {from?: string; to?: string}) => [
    ...itSupportKeys.summary(),
    'ticket-count',
    {date},
  ],
}

export const formKeys = {
  all: ['form'] as const,
  listFilters: () => [...formKeys.all, 'list-filters'] as const,
  lists: (workflowId: string) => [...formKeys.all, workflowId, 'list'] as const,
  list: (workflowId: string, queries?: FormQueries) =>
    [...formKeys.lists(workflowId), queries] as const,
  details: () => [...formKeys.all, 'details'] as const,
  detail: (id: string) => [...formKeys.details(), id] as const,
  data: (id: string) => [...formKeys.all, 'data', id] as const,
}

export const leaveKeys = {
  all: ['leave'] as const,
  // leave types
  typeList: () => [...leaveKeys.all, 'type-list'] as const,
  // leave policies
  policyList: (queries: LeaveQueries) => [...leaveKeys.all, 'policy-list', {queries}] as const,
  policyListNoQueries: () => [...leaveKeys.all, 'policy-list'] as const,
  exportData: ['leave-policies-export-data'] as const,
  details: (id: string) => [...leaveKeys.all, 'policy', id] as const,
  detailsEmp: (id: string) => [...leaveKeys.all, 'policy', id, 'employees'] as const,
  customColumns: ['leave-policy-custom-columns'] as const,
  listFilters: ['leave-policy-list-filters'] as const,
  manageEmpList: ['manage-employees-list'] as const,
  manageEmpConditions: ['manage-employees-conditions'] as const,
  // approver
  approvers: () => [...leaveKeys.all, 'approvers'] as const,
  employees: query => ['leave-employees', query] as const,
}

export const timeAwayV2Keys = {
  all: ['time-away-v2'] as const,
  details: (userId: string) => [...timeAwayV2Keys.all, 'details', userId],
  leaveConfig: (userId: string) => [...timeAwayV2Keys.all, 'leave-config', userId],
  leaveActivities: (userId: string, period: LeaveActivitiesQueryParams) => [
    ...timeAwayV2Keys.all,
    'leave-activities',
    userId,
    period.start,
    period.end,
  ],
}

export const hiringTemplateKeys = {
  all: ['hiring-template'] as const,
  tabs: () => [...hiringTemplateKeys.all, 'tabs'] as const,
  subCategoryFields: (tabId: string) => [...hiringTemplateKeys.all, tabId, 'fields'] as const,
  lists: () => [...hiringTemplateKeys.all, 'list'] as const,
  list: (query?: HiringTemplateQueries) => [...hiringTemplateKeys.lists(), query] as const,
  cache: () => [...hiringTemplateKeys.all, 'cache'] as const,
  details: () => [...hiringTemplateKeys.all, 'details'] as const,
  detail: (id: string) => [...hiringTemplateKeys.details(), id] as const,
}

export const attendancePolicyKeys = {
  all: ['attendance-policy-list'] as const,
  policyList: (queries: AttendacePolicyQueries) =>
    [...attendancePolicyKeys.all, 'attendance-policy-list', {queries}] as const,
  policyListNoQueries: () => [...attendancePolicyKeys.all, 'attendance-policy-list'] as const,
  customColumns: () => ['attendance-policy-custom-columns'] as const,
  listFilters: () => ['attendance-policy-filters'] as const,
  details: (id: string) => [...attendancePolicyKeys.all, 'policy', id] as const,
  detailsEmp: (id: string) => [...attendancePolicyKeys.all, 'policy', id, 'employees'] as const,
  listTimezone: () => ['attendance-policy-timezone'] as const,
  listCycleFrequency: () => ['policy-cycle-frequency'] as const,
  manageEmpConditions: ['manage-employees-conditions'] as const,
  manageEmpList: ['manage-employees-list'] as const,
  approvers: () => [...attendancePolicyKeys.all, 'approvers'] as const,
  timesheetsAll: ['attendance-timesheets-all'] as const,
  timesheetsList: ({queries, selectedCycle, policyId}) =>
    [
      ...attendancePolicyKeys.timesheetsAll,
      'attendance-timesheets-list',
      {queries},
      selectedCycle,
      policyId,
    ] as const,
  timesheetsListXlsx: queries =>
    [
      ...attendancePolicyKeys.timesheetsAll,
      'attendance-timesheets-download-list',
      {queries},
    ] as const,

  timesheetsFilters: ({cycleId, policyId}) =>
    [
      ...attendancePolicyKeys.timesheetsAll,
      `attendance-timesheets-filter${cycleId}${policyId}`,
      cycleId,
      policyId,
    ] as const,
  timesheetsCustomColumn: () =>
    [...attendancePolicyKeys.timesheetsAll, 'attendance-timesheets-custom-column'] as const,
  timesheetsAttendancePolicyList: () =>
    [...attendancePolicyKeys.timesheetsAll, 'attendance-timesheets-policy-list'] as const,
  timesheetsAttendancePolicyCycleList: id =>
    [...attendancePolicyKeys.timesheetsAll, 'attendance-timesheets-policy-cycle-list', id] as const,
  timeSheetCycleAll: ['attendance-timesheets-details'] as const,
  timesheetCycleDetails: cycleId => [...attendancePolicyKeys.timeSheetCycleAll, cycleId] as const,
  timesheetCycleUserTimeSheet: (cycleId, userId) =>
    [...attendancePolicyKeys.timeSheetCycleAll, cycleId, userId] as const,
  regularizeTimesheetReasonList: ['regularize-timesheet-reason'] as const,
  exportData: ['attendance-timesheet-export-data'] as const,
  workSchedule: id => ['work-schedule', id] as const,
  downloadUserTimesheet: (id, userId) => ['user-time-sheet', id, userId] as const,
}
export const calendarKeys = {
  all: ['calendar'] as const,
  publicHolidayCalendar: () => [...calendarKeys.all, 'public-holiday'] as const,
  companyPublicHolidayCalendar: () => [...calendarKeys.all, 'company-public-holiday'] as const,
  publicHolidays: (id: string) => [...calendarKeys.all, id, 'public-holiday-list'] as const,
  calendarUsers: () => [...calendarKeys.all, 'users'] as const,
}

export const zenAllianceKeys = {
  all: ['zen-alliance'] as const,
  getPartnerships: () => [...zenAllianceKeys.all, 'get-partnerships'] as const,
  getResellers: () => [...zenAllianceKeys.all, 'get-resellers'] as const,
}

export const reportKeysV2 = {
  all: ['report-list'] as const,
  defaultTemplate: ['report-default-template'] as const,
  folderOptions: ['report-folder-options'] as const,
  reportList: queries => [...reportKeysV2.all, 'report-list', queries] as const,
  folderList: queries => [...reportKeysV2.all, 'folder-list', queries] as const,
  recentReportList: () => [...reportKeysV2.all, 'recent-report-list'] as const,
  folderReportList: (queries, folderId) =>
    [...reportKeysV2.all, 'folder-report-list', queries, folderId] as const,
  folderDetails: (folderId: string) => [...reportKeysV2.all, 'folder-details', folderId] as const,
  reportDetails: (reportId: string) => [...reportKeysV2.all, 'report-details', reportId] as const,
  reportDetailsColumn: (reportId: string) =>
    [...reportKeysV2.all, 'report-details-column', reportId] as const,
  reportDetailsData: ({reportId, queries}: {reportId: string; queries: ReportDetailsListQueries}) =>
    [...reportKeysV2.all, 'report-details-data', reportId, queries] as const,
  reportDetailsInitialInfo: (reportId: string) =>
    [...reportKeysV2.all, 'report-details-initial-info', reportId] as const,
  reportDetailsFilter: (reportId: string) =>
    [...reportKeysV2.all, 'report-details-filter', reportId] as const,
  reportListFilter: () => [...reportKeysV2.all, 'report-list-filter'] as const,
  folderListFilter: () => [...reportKeysV2.all, 'folder-list-filter'] as const,
}
