import * as React from 'react'
import HUIDrawer from 'hybrid-ui/components/hui-drawer'
import SidebarMenu from '../sidebar/sidebar-menu'
import ProfileBox from '../sidebar/profile-box'
import CompanyBox from '../sidebar/company-box'
import {useLocation} from 'react-router-dom'
import {useSingleModuleControl} from 'app/routing/module-control'
import {BookTimeAwayAction} from '../sidebar/sidebar-actions'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'

export function MenuInner({isOpen, onClose}) {
  const {hasSingleAccess} = useSingleModuleControl('hris')
  const {isOpen: timeAwayIsOpen, onClose: onTimeAwayClose, onOpen: onTimeAwayOpen} = useDisclosure()

  const location = useLocation()

  React.useEffect(() => {
    if (isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  React.useEffect(() => {
    window.addEventListener('resize', function (e: UIEvent) {
      const target = e.target as Window
      if (target?.innerWidth > 991) {
        onClose()
      }
    })
    return () => {
      window.removeEventListener('resize', function () {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const headerTitle = (
    <div style={{marginBottom: '-16px', width: '100%'}}>
      <CompanyBox isMobile />
    </div>
  )

  const footer = (
    <div style={{padding: '0 20px'}}>
      {hasSingleAccess && (
        <BookTimeAwayAction
          isMobile
          isOpen={timeAwayIsOpen}
          onClose={onTimeAwayClose}
          onOpen={onTimeAwayOpen}
        />
      )}
      <ProfileBox isMobile />
    </div>
  )

  // todo @AbhishekHybr1d replace this drawer with the new one
  return (
    <HUIDrawer
      title={headerTitle}
      isOpen={isOpen}
      onClose={onClose}
      overrideStyles={{
        backgroundColor: 'var(--neutral-white)',
        color: 'white',
      }}
      overrideHeaderStyles={{
        color: 'white',
      }}
      overrideOffcanvasHeaderStyles={{
        padding: '0px',
        marginBottom: '10px',
      }}
      showCloseBtn={false}
      drawerFooter={footer}
    >
      <SidebarMenu isMobile={true} />
    </HUIDrawer>
  )
}
