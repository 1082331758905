import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'
import {ICONS} from 'app/utils/constants/icon'
import {useCreateWorkflowStore} from 'app/store/workflow'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import DeleteNodeModal from '../../../all-modals/delete-node-modal'

import classes from './styles.module.css'
import {isMiddleNodeBottomHandleConnectable} from 'app/utils/helper/create-workflow'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'

export default function MergeNode(props: NodeProps) {
  const {
    data: {nodeContent},
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {deleteNode, handleDrawerState, edges, nodes} = useCreateWorkflowStore(state => ({
    deleteNode: state.deleteNode,
    handleDrawerState: state.handleDrawerState,
    nodes: state.nodes,
    edges: state.edges,
  }))
  const handleSelect = (type: DRAWER_CONTENT_TYPE, icon?: string) => {
    handleDrawerState(type, true, props.id, false, icon)
  }

  const handleNodeDelete = () => {
    deleteNode(props.id)
    onClose()
  }

  return (
    <div>
      <div className={classes.container} onClick={() => handleSelect(props.data.drawerContent)}>
        <Handle
          type="target"
          position={Position.Top}
          style={{background: '#555'}}
          onConnect={params => console.log('handle onConnect', params)}
          isConnectable={true}
        />
        <CustomNodeWrapper isSelected={props.selected} style={{padding: '8px 12px'}}>
          <div className={classes.nodeContent}>
            <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
          </div>
        </CustomNodeWrapper>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{background: '#555'}}
          onConnect={params => console.log('handle onConnect', params)}
          isConnectable={isMiddleNodeBottomHandleConnectable(nodes, edges, props.id)}
        />

        {/* {endNodes?.includes(props.id) && ( */}
        <div className={classes.imageContainer}>
          <img
            src={ICONS.delete.deleteOutline}
            onClick={e => {
              e.stopPropagation()
              onOpen()
            }}
            alt={'delete'}
            className={classes.deleteIcon}
            title="Delete node"
          />
        </div>
        {/* )} */}
      </div>
      <DeleteNodeModal
        bodyTitle={'Deleting this node will disconnect the subsequent steps of the workflow.'}
        isOpen={isOpen}
        onClose={onClose}
        onOk={handleNodeDelete}
      />
    </div>
  )
}
